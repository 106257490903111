@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mixins;

.container {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: fit-content;
  justify-content: center;
  overflow-y: hidden;
  position: relative;
  content-visibility: auto;

  &.bannerContainer {
    min-height: functions.spacing(80);
  }

  &.withBackgroundColor {
    padding-bottom: functions.spacing(4);
    padding-top: functions.spacing(4);
  }

  @include breakpoints.up(769px) {
    gap: functions.spacing(8);
  }

  @include breakpoints.up(lg) {
    gap: 0;
  }

  &--anchor-buttons {
    background-color: white;
    position: sticky;
    top: functions.px-to-rem(164px);
    transition: top 300ms ease-in-out;
    z-index: 3; // Default header is z-index 4

    @include breakpoints.up(md) {
      top: functions.px-to-rem(156px);
    }

    @include breakpoints.up(lg) {
      top: functions.px-to-rem(126px);
    }
  }
}

.flexDirection {
  &--row {
    @include breakpoints.up(md) {
      flex-direction: row;
    }
  }

  &--row-reverse {
    @include breakpoints.up(md) {
      flex-direction: row-reverse;
    }
  }

  &--column {
    @include breakpoints.up(md) {
      flex-direction: column;
    }
  }
}

@include mixins.margin-lateral();
@include mixins.margin-vertical();
