@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mixins;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(4);
  justify-content: center;
  margin: functions.spacing(0, 11, 10);

  &.withTopPadding {
    padding-top: functions.spacing(6);
  }

  @include breakpoints.up(600px) {
    flex-direction: row;
  }

  @include breakpoints.up(lg) {
    margin: functions.spacing(0, 11, 16);
  }
}

@include mixins.margin-lateral();
@include mixins.margin-vertical();
