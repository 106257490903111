@use '@styles/mixins/breakpoints' as breakpoints;

@include breakpoints.down(md) {
  .desktop {
    display: none;
  }
}

@include breakpoints.up(1025px) {
  .tablet {
    display: none;
  }
}

@include breakpoints.up(769px) {
  .mobile {
    display: none;
  }
}
