@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

@mixin margin-lateral {
  .marginLateral {
    margin-left: functions.spacing(4);
    margin-right: functions.spacing(4);

    @include breakpoints.up(md) {
      margin-left: functions.spacing(8);
      margin-right: functions.spacing(8);
    }

    @include breakpoints.up(lg) {
      margin-left: functions.spacing(16);
      margin-right: functions.spacing(16);
    }

    @include breakpoints.up(xl) {
      margin-left: functions.spacing(8);
      margin-right: functions.spacing(8);
    }
  }
}

@mixin margin-vertical {
  .marginVerticalX {
    margin-top: functions.spacing(0);
    margin-bottom: functions.spacing(0);
  }

  .marginVerticalS {
    margin-top: functions.spacing(8);
    margin-bottom: functions.spacing(8);

    @include breakpoints.down(sm) {
      margin-top: functions.spacing(4);
      margin-bottom: functions.spacing(4);
    }
  }

  .marginVerticalM {
    margin-top: functions.spacing(10);
    margin-bottom: functions.spacing(10);
  }

  .marginVerticalL {
    margin-top: functions.spacing(0);
    margin-bottom: functions.spacing(0);
  }

  .marginVerticalMobileM {
    @include breakpoints.down(769px) {
      margin-top: functions.spacing(8);
      margin-bottom: functions.spacing(8);
    }
  }
}
