@use '@styles/mixins/breakpoints' as breakpoints;

@include breakpoints.up(600px) {
  .mediaMobile {
    display: none;
  }
}

@include breakpoints.down(599px) {
  .mediaDesktop {
    display: none;
  }
}
