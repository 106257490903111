@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

// Product Tile CSS Override
.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--wildSand);
  padding: 0;
  height: 100%;

  @include breakpoints.up(lg) {
    justify-content: center;
    padding-block: functions.spacing(4);
    padding-top: functions.spacing(4);
  }

  @include breakpoints.up(xl) {
    padding-top: functions.spacing(8);
  }
}

.imageContainer {
  max-width: 100%;
}

.imageWrapper {
  mix-blend-mode: multiply;
}

.footer {
  .productName {
    padding: 0 functions.spacing(1);
  }
}
