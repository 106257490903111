@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/mixins/add-shadow' as *;

$shadow-dark-color: rgba(0, 0, 0, 0.5);
$shadow-light-color: rgba(255, 255, 255, 0.5);

.media-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 320px;

  $mediaWrapper: &;

  &.is-video {
    padding-bottom: initial;
    height: auto;
  }

  picture,
  video,
  img,
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .loaded {
    + svg {
      display: none;
    }
  }

  .cms-section[class*='-shadow'] & {
    $shadow-section: &;

    &.has-overlay {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        bottom: 10px;
        background: transparent;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, transparent 50%);

        $after-class: &;

        @include breakpoints.up(xl) {
          @include add-shadow($after-class, $shadow-light-color);
        }
      }

      @at-root {
        [class*='light']#{$shadow-section} {
          &::after {
            $after-class: &;

            @include breakpoints.up(xl) {
              @include add-shadow($after-class, $shadow-dark-color);
            }
          }
        }
      }
    }
  }
}
