@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.countdown {
  color: black;
  line-height: functions.spacing(10);
  margin-bottom: functions.spacing(4);
  text-align: center;

  @include breakpoints.up(md) {
    font-size: 1.5rem;
    margin-bottom: functions.spacing(6);
    text-align: left;
  }
}
