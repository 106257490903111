@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

// Product Tile CSS Override


.imageWrapper {  
  height: functions.px-to-rem(150px);

  @include breakpoints.down(md) {
    height: 100%;
  }
}


