@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

@mixin sharedStyle($selector) {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: functions.spacing(2);

  @include breakpoints.up(lg) {
    flex-direction: row;
  }

  :global(.text-light-primary) {
    color: var(--text-primary-light);
  }

  & .banner {
    width: 100%;
    position: relative;
    display: flex;

    & > section {
      height: 528px;

      @include breakpoints.up(lg) {
        height: 680px;
      }

      :global(a.cmsMediaLink) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }

  & .products {
    display: flex;
    flex: 1 1;
    gap: functions.spacing(2);

    @include breakpoints.up(lg) {
      height: 680px;
    }

    & a {
      height: 100%;
      padding-top: 0;
      display: flex;
      justify-content: center;
      background-color: var(--wildSand);

      & img {
        height: 100px;
        max-height: 100px;

        @include breakpoints.up(sm) {
          height: 130px;
          max-height: 130px;
        }

        @include breakpoints.up(lg) {
          height: 170px;
          max-height: 170px;
        }
      }
    }
  }

  & .item {
    flex: 1 1 0;
    overflow: hidden;
    background-color: var(--wildSand);
    padding-bottom: functions.spacing(2);
    padding-inline: functions.px-to-rem(16px);
    flex-direction: column;

    @include breakpoints.down(sm) {
      max-height: 100%;
      padding-bottom: functions.spacing(2);
    }
  }

  :global(.cta-container) {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: functions.spacing(1);
    width: 100%;
    pointer-events: fill;

    & a {
      width: fit-content;

      @include breakpoints.down(md) {
        flex-grow: 1;
        min-width: 0;
        width: 100%;
      }
    }

    & > :first-child {
      margin-left: 0;
      @include breakpoints.down(md) {
        margin-left: functions.spacing(2);
      }
    }

    @include breakpoints.down(md) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-inline: auto;
      width: unset;
    }
  }
}

.box-with-products-box-and-2-products {
  @include sharedStyle(&);

  & .banner {
    flex: 2 1;
  }

  & .products {
    flex-direction: row;

    @include breakpoints.up(lg) {
      flex-direction: column;
    }
  }

  & .item {
    max-height: calc(50% - functions.spacing(1));
  }
}

.box-with-products-box-and-4-products {
  @include sharedStyle(&);

  & .banner {
    flex: 1;
  }

  & .products {
    flex-wrap: wrap;

    & .item {
      flex: 1 0 calc(50% - functions.spacing(1));
    }
  }

  :global(.cta-container) {
    @include breakpoints.between(769px, xl) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: unset;

      & > a {
        margin-left: 0;

        flex-grow: 1;
        min-width: 0;
        width: 100%;
      }
    }
  }
}

.box-with-products-reflect {
  @include breakpoints.up(lg) {
    flex-direction: row-reverse;
  }
}

.textModuleBoxAndProducts {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: functions.spacing(4);
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;

  & > :global(div:first-child) {
    display: flex;
    flex-direction: column;
    gap: functions.spacing(1);
    margin-bottom: functions.spacing(4);
    width: 100%;

    @include breakpoints.down(md) {
      align-items: center;
    }

    & > :global(div:first-child) {
      @include breakpoints.down(sm) {
        text-align: center;
      }
    }
  }

  :global(h2) {
    margin: 0;
  }

  :global(p) {
    margin: 0;

    @include breakpoints.down(md) {
      text-align: center;
    }
  }
}

.productTileWrapper {
  height: 100%;

  :global(.productTileSquare) {
    & > div:nth-child(3) {
      margin-top: functions.spacing(4);

      & > div {
        padding: 0 functions.spacing(4);

        & > div {
          text-align: center;
        }
      }
    }
  }
}
