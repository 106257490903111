@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.termsAndConditions {
  cursor: pointer;
  display: flex;
  justify-content: center;

  @include breakpoints.up(769px) {
    bottom: 0;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    width: 100%;
  }
}

.termsAndConditionsCta {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  gap: functions.spacing(2);
  line-height: 1.5;

  &::after {
    display: none;
  }

  &:hover {
    color: black;
  }

  @include breakpoints.up(769px) {
    font-size: 0.875rem;
    font-weight: 600;

    &:hover {
      color: white;
    }
  }
}
