@use '@styles/functions' as functions;
@use '@styles/_variables.scss' as variables;

.shoppableTeaser {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: variables.$z-index-lv1;

  &:hover {
    .shoppableTeaserProducts {
      opacity: 1;
      visibility: visible;
    }
  }
}

.shoppableTeaserIcon {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--primary-dark);
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  pointer-events: none;

  .shoppableTeaserLight & {
    background-color: white;
  }

  &:hover,
  &:focus {
    + .shoppableTeaserProducts {
      opacity: 1;
      visibility: visible;
    }
  }

  svg {
    width: 1rem;
    fill: var(--text-primary-light);

    path {
      fill: var(--text-primary-light);
    }

    .shoppableTeaserLight & {
      fill: var(--primary-dark);

      path {
        fill: var(--primary-dark);
      }
    }
  }
}

.shoppableTeaserLink {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow:
    0 5.9px 11.8px 0 rgba(0, 0, 0, 0.16),
    0 0 2.9px 0 rgba(0, 0, 0, 0.04);
  color: var(--text-primary-dark);
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  line-height: 1rem;
  min-height: 86px;
  min-width: 97px;
  padding: functions.spacing(2);
  text-decoration: none;

  width: 97px;
}

.shoppableTeaserProducts {
  position: absolute;
  display: flex;
  gap: 8px;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0s linear 0.3s,
    opacity 0.3s;
  line-height: 1.33;
  text-align: center;
  z-index: variables.$z-index-lv1;

  :global(.media-wrapper) {
    height: 100%;
    position: relative;

    img {
      height: 45px;
    }
  }
}

.shoppableTeaserImageContainer img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
