@mixin add-shadow($after-class, $background-color) {
  @at-root {
    .block-left-top#{#{$after-class}},
    .block-left-middle#{#{$after-class}} {
      background: linear-gradient(90deg, $background-color 25%, transparent 50%);
    }

    .block-left-bottom#{#{$after-class}},
    .block-right-bottom#{#{$after-class}},
    .block-center-bottom#{#{$after-class}} {
      background: linear-gradient(0deg, $background-color 25%, transparent 50%);
    }

    .block-right-top#{#{$after-class}},
    .block-right-middle#{#{$after-class}} {
      background: linear-gradient(270deg, $background-color 25%, transparent 50%);
    }

    .block-center-middle#{#{$after-class}} {
      background: linear-gradient(0deg, $background-color 25%, transparent 50%);
    }
  }
}
