@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mixins;

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: functions.spacing(2);
  margin-left: 0;
  margin-right: 0;
  padding: functions.spacing(10, 0, 0);
  text-align: center;

  @include breakpoints.up(1025) {
    gap: functions.spacing(4);
    padding: functions.spacing(16, 0, 0);
  }

  @include breakpoints.up(xxl) {
    padding: functions.spacing(16, 44, 0);
  }

  &.withBottomPadding {
    padding-bottom: functions.spacing(6);
  }
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: none;
  margin: 0;
}

.subTitle {
  font-size: 1rem;
  margin: 0;
}

.text {
  font-size: 1rem;

  p {
    margin: 0;
  }
}

@include mixins.margin-lateral();
